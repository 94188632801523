import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Icon, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdAssignmentTurnedIn } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Про наші послуги | Luminetic футбольне поле
			</title>
			<meta name={"description"} content={"Ваш найкращий футбольний досвід чекає!"} />
			<meta property={"og:title"} content={"Про наші послуги | Luminetic футбольне поле"} />
			<meta property={"og:description"} content={"Ваш найкращий футбольний досвід чекає!"} />
			<meta property={"og:image"} content={"https://lumineticswift.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://lumineticswift.com/img/14676665.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://lumineticswift.com/img/14676665.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://lumineticswift.com/img/14676665.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://lumineticswift.com/img/14676665.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://lumineticswift.com/img/14676665.png"} />
			<meta name={"msapplication-TileImage"} content={"https://lumineticswift.com/img/14676665.png"} />
			<meta name={"msapplication-TileColor"} content={"https://lumineticswift.com/img/14676665.png"} />
		</Helmet>
		<Components.Header />
		<Section
			text-align="center"
			background-color="--green"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
			background="rgba(0, 135, 90, 0.99)"
		>
			<Text
				as="h5"
				font="--lead"
				margin="10px 0 0 0"
				text-transform="uppercase"
				letter-spacing="5px"
			>
				Оренда футбольних полів преміум-класу
			</Text>
			<Text as="h1" font="--headline1" md-font="--headline2" margin="10px 0 0 0">
			Про наші послуги
			</Text>
			<Text as="p" font="--lead" margin="40px 0 20px 0">
			Наша основна послуга - оренда високоякісних футбольних полів. Ми пропонуємо різноманітні варіанти, що відповідають різним вимогам, незалежно від того, чи організовуєте ви звичайну гру, чи змагальний матч, чи масштабний турнір. Наші поля підтримуються за найвищими стандартами, забезпечуючи безпечне та приємне ігрове середовище.
			</Text>
			<Box min-width="100px" min-height="100px">
				<Button
					type="link"
					font="--headline3"
					text-decoration-line="initial"
					color="--light"
					border-radius="8px"
					border-width="1px"
					border-style="solid"
					border-color="--color-light"
					background="rgba(0, 119, 204, 0)"
					href="/contact-us"
				>
					Контакти
				</Button>
			</Box>
		</Section>
		<Section color="--dark">
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
			Найсучасніші умови
			</Text>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Сучасне штучне покриття: На наших полях використовується найновіша технологія штучного покриття, що забезпечує стабільну ігрову поверхню, яка імітує натуральну траву, зменшуючи при цьому ризик травм.
				<br/><br/>
				Довговічність: Газон розроблений таким чином, щоб витримувати інтенсивне використання, гарантуючи, що він залишатиметься в ідеальному стані протягом усього року.
				<br/><br/>
				Безпека: Розроблено для забезпечення кращого поглинання ударів, зменшення впливу на суглоби та мінімізації ризиків травмування.

				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Професійне освітлення: Наші поля обладнані високоякісними системами освітлення, що дозволяє грати до пізнього вечора.
				<br/><br/>
				Видимість: Забезпечує відмінну видимість, що дозволяє безпечно і приємно грати вночі.
				<br/><br/>
				Енергоефективність: Використовує новітні енергозберігаючі технології, забезпечуючи яскраве освітлення без високих енерговитрат.
				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Просторі роздягальні: Ми надаємо комфортні та чисті роздягальні, щоб гравці могли підготуватися та відпочити.
				<br/><br/>
				Зберігання: Достатньо місця в шафках для особистих речей та спорядження.
				<br/><br/>
				Душові кабіни: Сучасні душові кабіни, щоб освіжитися після гри.

				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Парковка на території: Достатньо місця для паркування для легкого та зручного доступу до наших об'єктів.
				<br/><br/>
				Безпека: Парковка під охороною для забезпечення безпеки вашого автомобіля.
				<br/><br/>
				Доступність: Легкі точки доступу для гравців та відвідувачів з обмеженими можливостями.

				</Text>
			</Box>
			<Box
				margin="36px 0 0 0"
				padding="0 0 0 54px"
				sm-padding="54px 0 0 0"
				position="relative"
				sm-margin="24px 0 0 0"
			>
				<Icon
					position="absolute"
					top="8px"
					left="0"
					size="36px"
					color="--green"
					category="md"
					icon={MdAssignmentTurnedIn}
				/>
				<Text as="p" font="--lead" margin="10px 0" color="--greyD2">
				Кафетерій: Насолоджуйтесь різноманітними закусками та напоями до або після гри.
				<br/><br/>
				Здорові варіанти: Вибір здорових закусок та напоїв, які допоможуть вам зарядитися енергією.
				<br/><br/>
				Зручні місця для сидіння: Розслаблена зона, де можна посидіти та поспілкуватися з іншими гравцями.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://lumineticswift.com/img/2.jpg"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://lumineticswift.com/img/3.jpg"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://lumineticswift.com/img/4.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://lumineticswift.com/img/5.jpg"
							position="absolute"
							width="100%"
							left={0}
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://lumineticswift.com/img/6.jpg"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					Не чекайте більше, щоб випробувати найкращі футбольні майданчики в місті. Незалежно від того, чи хочете ви орендувати поле, приєднатися до тренувальної програми або скористатися нашими першокласними зручностями, Luminetic Soccer Fields має все, що вам потрібно. Забронюйте тренування вже сьогодні та покращіть свій футбольний досвід.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});